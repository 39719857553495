import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import { Snackbar } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "snackbar"
    }}>{`Snackbar`}</h1>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface SnackbarProps {
  autoClose?: boolean; //indica se a snackbar vai fechar sozinha depois de um tempo determinado - default = true
  timeToClose?: number; // tempo em milisegundos para a snackbar fechar, quando autoClose = true - default = 6000
}

export interface ISnackbarConstructor {
  new (props?: SnackbarProps): ISnackbar;
}

export type VariantType = 'success' | 'error'; // variantes da snackbar, indica o padrão de cor

export interface ISnackbar {
  label: string; //texto da snackbar
  variant: VariantType; //variantes da snackbar
  open: boolean; //indica se a snackbar está aberta ou fechada, se passar algum valor, a snackbar abre/true ou fecha/false - defaulr = false
  readonly destroy: () => void; //método público, remove todos os elementos da página

  onClose?: () => void; //disparado quando a snackbar tem intensão de fechar
  onOpen?: () => void; //disparado quando termina a animação de abrir
  onClosed?: () => void; //disparado quando termina a animação de fechar
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Nota: a snackbar não fecha de forma automatizada, apenas indica que tem intenção de fechar, através do evento onClose. Ou seja,
deve-se implementar essa função setando a propriedade `}<strong parentName="p">{`open`}</strong>{` para false. Esse evento é disparado quando termina o tempo do autoClose (se true),
quando clica na área fora da snackbar, ou quando clica no botão fechar. Consulte o exemplo a seguir:`}</p>
    </blockquote>
    <Playground __position={0} __code={'() => {\n  const [snackbar, setSnackbar] = useState(null)\n  const [isOpen, setIsOpen] = useState(false)\n  //\n  useEffect(() => {\n    if (!snackbar) {\n      setSnackbar(new Snackbar())\n    }\n    //\n    if (snackbar) {\n      snackbar.onClose = onClose\n    }\n    //\n    return () => {\n      if (snackbar) snackbar.destroy()\n    }\n  }, [snackbar])\n  //\n  useEffect(() => {\n    if (snackbar) {\n      snackbar.open = isOpen\n    }\n  }, [snackbar, isOpen])\n  //\n  onOpen = type => () => {\n    if (snackbar) {\n      switch (type) {\n        case \'success\':\n          snackbar.label = \'Dados enviados com sucesso.\'\n          break\n        case \'error\':\n          snackbar.label = \'Falha no envio.\'\n          break\n      }\n      //\n      snackbar.variant = type\n    }\n    setIsOpen(true)\n  }\n  //\n  onClose = () => {\n    setIsOpen(false)\n  }\n  return (\n    <div className=\"tw-flex\">\n      <button className=\"tw-btn tw-btn-primary\" onClick={onOpen(\'success\')}>\n        success\n      </button>\n      <button\n        className=\"tw-btn tw-btn-secondary tw-ml-4\"\n        onClick={onOpen(\'error\')}\n      >\n        error\n      </button>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Snackbar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [snackbar, setSnackbar] = useState(null);
        const [isOpen, setIsOpen] = useState(false); //

        useEffect(() => {
          if (!snackbar) {
            setSnackbar(new Snackbar());
          } //


          if (snackbar) {
            snackbar.onClose = onClose;
          } //


          return () => {
            if (snackbar) snackbar.destroy();
          };
        }, [snackbar]); //

        useEffect(() => {
          if (snackbar) {
            snackbar.open = isOpen;
          }
        }, [snackbar, isOpen]); //

        onOpen = type => () => {
          if (snackbar) {
            switch (type) {
              case 'success':
                snackbar.label = 'Dados enviados com sucesso.';
                break;

              case 'error':
                snackbar.label = 'Falha no envio.';
                break;
            } //


            snackbar.variant = type;
          }

          setIsOpen(true);
        }; //


        onClose = () => {
          setIsOpen(false);
        };

        return <div className="tw-flex">
        <button className="tw-btn tw-btn-primary" onClick={onOpen('success')}>
          success
        </button>
        <button className="tw-btn tw-btn-secondary tw-ml-4" onClick={onOpen('error')}>
          error
        </button>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      